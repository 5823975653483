import { Subscription } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { effect, Component, OnDestroy } from '@angular/core';

import { GALLERY_DEFAULT_PAGE, GALLERY_DEFAULT_PAGE_SIZE, GALLERY_PRODUCT_SCROLL_POSITION_COOKIE_NAME } from '@lib-core/constants';

import { GroupBoxComponent } from '@lib-core/components';
import { GalleryCardComponent } from '@proj-b2b/app/modules/gallery/components/gallery-card/gallery-card.component';
import { GalleryEmptyComponent } from '@proj-b2b/app/modules/gallery/components/gallery-empty/gallery-empty.component';
import { get, set, clear, scrollToTop } from '@lib-core/helpers';
import { GalleryService, RouterService, ToastService } from '@lib-core/services';
import { ProductModel } from '@lib-core/models';
import { GalleryRequestDto, GalleryResponseDto } from '@lib-core/dtos';
import { GalleryRequestStore, GalleryResponseStore, SideFilterStore } from '@lib-core/stores';

@Component({
	standalone: true,
	selector: 'app-gallery-list',
	templateUrl: './gallery-list.component.html',
	imports: [FormsModule, CommonModule, GroupBoxComponent, NgbPaginationModule, GalleryCardComponent, GalleryEmptyComponent],
})
export class GalleryListComponent implements OnDestroy {
	private readonly subscriptionList: Subscription[] = [];

	searchTerm: string = '';
	request: GalleryRequestDto = null;
	response: GalleryResponseDto = null;

	constructor(
		private readonly toastService: ToastService,
		private readonly routerService: RouterService,
		private readonly galleryService: GalleryService,
		private readonly sideFilterStore: SideFilterStore,
		private readonly galleryRequestStore: GalleryRequestStore,
		private readonly galleryResponseStore: GalleryResponseStore,
	) {
		effect(() => {
			this.response = this.galleryResponseStore.get();
		});

		effect(() => {
			this.request = this.galleryRequestStore.get();
			this.searchTerm = this.request.productCode || this.request.productDescription;
			this.query();
		});
	}

	get pageSizeLabel() {
		return this.response?.totalCount < this.response?.pageSize ? this.response?.totalCount : this.response?.pageSize;
	}

	get showingProductsCountLabel() {
		return this.hasProducts ? `Exibindo <strong>${this.pageSizeLabel}</strong> de <strong>${this.response?.totalCount}</strong> produto(s)` : '';
	}

	get showClearSearchButton() {
		return this.searchTerm?.length > 0;
	}

	get hasProducts() {
		return this.response?.products?.length > 0;
	}

	get isPageReady() {
		return !!this.request;
	}

	ngOnDestroy() {
		this.request = null;
		this.subscriptionList.forEach(subscription => subscription.unsubscribe());
	}

	clearSearchTerm() {
		this.searchTerm = '';
		this.queryProduct();
	}

	queryProduct() {
		const trimmedSearchTerm = this.searchTerm.trim();
		const isProductCode = !isNaN(Number(trimmedSearchTerm));

		this.request.page = GALLERY_DEFAULT_PAGE;
		this.request.pageSize = GALLERY_DEFAULT_PAGE_SIZE;
		this.request.productCode = isProductCode ? trimmedSearchTerm : '';
		this.request.productDescription = isProductCode ? '' : trimmedSearchTerm;

		this.galleryRequestStore.set(this.request);
	}

	query() {
		this.subscriptionList.push(
			this.galleryService.query(this.request).subscribe({
				next: response => {
					if (response.success) {
						this.galleryResponseStore.set(response.data);
						this.shouldScrollToTop();
						return;
					}
					this.toastService.danger('Erro ao buscar produtos');
					this.galleryResponseStore.clear();
				},
				error: error => {
					console.error('Error querying products', error);
				},
			}),
		);
	}

	queryPage(page: number) {
		clear(GALLERY_PRODUCT_SCROLL_POSITION_COOKIE_NAME);
		this.request.page = page;
		this.galleryRequestStore.set(this.request);
	}

	addProductToCart(product: ProductModel) {
		this.saveScrollPosition();
		this.routerService.goToProduct(product.code);
	}

	saveScrollPosition() {
		const scrollPosition = window.scrollY;
		set(GALLERY_PRODUCT_SCROLL_POSITION_COOKIE_NAME, scrollPosition);
	}

	setProductsPerPage(event: Event): void {
		const selectElement = event.target as HTMLSelectElement;
		this.request.pageSize = Number(selectElement.value);
		this.galleryRequestStore.set(this.request);
	}

	shouldScrollToTop() {
		const top = get(GALLERY_PRODUCT_SCROLL_POSITION_COOKIE_NAME);

		if (top) {
			scrollToTop(parseInt(top, 10));
			clear(GALLERY_PRODUCT_SCROLL_POSITION_COOKIE_NAME);
			return;
		}

		scrollToTop();
	}
}
