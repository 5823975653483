<div class="row" *ngIf="isPageReady" id="galleryView">
	<div class="col-24">
		<core-group-box>
			<ng-template #header>
				<label for="productSearch" class="form-label mb-0">O que você está procurando?</label>
			</ng-template>
			<ng-template #body>
				<div class="d-flex gap-2">
					<div class="input-group flex-grow-1">
						<input
							type="text"
							id="productSearch"
							class="form-control"
							placeholder="Busque por código ou nome do produto"
							[(ngModel)]="searchTerm"
							(keyup.enter)="queryProduct()" />
						<button
							type="button"
							class="btn bg-transparent"
							[style.z-index]="10"
							[style.margin-left]="'-42px'"
							(click)="clearSearchTerm()"
							(keyup.enter)="clearSearchTerm()"
							*ngIf="showClearSearchButton">
							<i class="bi bi-x"></i>
						</button>
					</div>
					<button class="btn btn-primary" (click)="queryProduct()">
						<i class="bi bi-search me-1"></i>
						Buscar
					</button>
				</div>
			</ng-template>
		</core-group-box>
	</div>

	<div class="col-24 text-end mt-2 mb-4 p-0 d-flex row justify-content-end align-items-center">
		<span class="text-body-secondary p-0">
			Exibindo
			<select class="form-select d-inline-block w-auto mx-1" (change)="setProductsPerPage($event)" [(ngModel)]="request.pageSize">
				<option *ngFor="let perPage of [12, 24, 36, 48]" [value]="perPage">
					{{ perPage }}
				</option>
			</select>
			de <strong>{{ response?.totalCount }}</strong> produto(s).
		</span>
	</div>

	<ng-container *ngIf="hasProducts; else noProducts">
		<div class="col-24 col-md-12 col-lg-8 col-xl-6 col-xxl-4 mb-4" *ngFor="let product of response?.products">
			<app-gallery-card [product]="product" (onAddClick)="addProductToCart($event)" (onDetailsClick)="addProductToCart($event)"></app-gallery-card>
		</div>

		<div class="d-flex justify-content-center">
			<ngb-pagination
				[maxSize]="7"
				aria-label="Default pagination"
				[collectionSize]="response?.totalCount"
				[pageSize]="response?.pageSize"
				[(page)]="request.page"
				(pageChange)="queryPage($event)" />
		</div>
	</ng-container>

	<ng-template #noProducts>
		<b2b-gallery-empty />
	</ng-template>
</div>
